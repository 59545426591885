<template>
  <div class="start-landing-teammate">
    <b-row no-gutters>
      <b-col>
        <h1 class="text-uppercase text-purpink text-center display-4"><strong>AI TEAMMATES:</strong> THE NEW REVOLUTION</h1>

        <h2 class="text-center text-dark">
          FREE SOFTWARE Revolutionize How To Really Use AI To Supercharge Every Aspect Of Your Business - Experience Not Needed!
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12">
        <StepsPromo @click="$emit('next')" :introduction="introduction" :steps="steps" />

        <div class="d-flex align-items-center justify-content-center flex-wrap mt-4">
          <div class="d-flex mr-3 mb-3">
            <img src="@/assets/images/forbes-thailand.png" width="100" class="mr-3 mb-3" />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="text-center text-dark text-capitalize">
          The Software Your Business Desperately <span class="font-handwriting">NEEDS NOW</span>
        </h2>

        <p class="text-dark">Do you want to take your business to the next level?</p>

        <p class="text-dark">
          You want to write more content, more blog post, more e-mails, but more importantly, of a BETTER QUALITY?
        </p>

        <p class="text-dark">
          I imagine that you’ve tried ChatGPT and others AI softwares… but you’ve almost lost more time than gained?
        </p>

        <p class="text-dark">
          The result seemed off, didn’t fit your voice, the output was just of a poor quality… It’s normal, and I’ll explain why
          in a few minutes.
        </p>

        <p class="text-dark">
          All I can tell you for now, is that this new AI software WORKS for any type of copywriting work… - Blog post - Emails -
          Sales pages - And the list goes on.
        </p>

        <p class="text-dark">If you want to produce more, without spending time and money on crazy prompts for ChatGPT, then…</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="text-center">
          <span class="text-uppercase text-dark">Meet Louis AI</span> <span class="font-handwriting text-dark">And His Team</span>
        </h2>

        <p class="text-dark">
          Louis AI is not just another “ChatGPT”.<br />
          Louis AI is your new business partner that you’re going to train with your OWN DATA.
        </p>

        <p class="text-dark">
          The results you’ll get will be UNLIKE anything you’ve tried before… Because Louis AI and his teammates will be trained
          BY YOU.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="text-center text-dark text-capitalize">
          Copy / Paste The URL Of Your Blog... AND LET LOUIS AI LEARN…
        </h2>

        <p class="text-dark">
          The problem with ChatGPT is NOT the technology behind it. It’s that it was trained with too much data.
        </p>

        <p class="text-dark">
          I’ll make it simple:<br />
          There is a dictum in the world of artificial intelligence:
        </p>

        <p class="text-dark">
          <strong>
            Garbage In. Garbage Out.
          </strong>
        </p>

        <p class="text-dark">
          ChatGPT was trained on a crazy amount of data. You could say that it’s lost, because it doesn’t know exactly what you
          want…
        </p>

        <p class="text-dark">
          That’s where Louis AI comes in.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="text-center text-dark text-capitalize">
          With Louis AI, You Can Train Your Own AI Teammates In Only A Few Minutes
        </h2>

        <p class="text-dark">
          Just click on create a teammate, copy / paste your text (e-mails, blog posts etc), and that’s it! Nothing more!
        </p>

        <p class="text-dark">
          The training process has never been that easy… and it makes a drastic difference. Not just drastic, it’s like another
          world.
        </p>

        <p class="text-dark">
          The output of the AI becomes relevant to you and your needs. You can even train multiple AI teammates, to write in
          different styles…
        </p>

        <p class="text-dark">Not only that.</p>

        <p class="text-dark">
          You can also train your own AI designer. Upload images that you like, and your AI designer will learn the style and use
          those as references to produce the images you want.
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="h2 font-handwriting text-center text-dark text-capitalize">
          Here's What Your New Partner Will Do For You
        </h2>

        <ul class="text-left mt-4 helvetica p-0">
          <li v-for="value in pros" :key="String(value)" class="d-flex mb-2 text-dark">
            <img src="@/assets/icons/check.svg" class="icon m-0" />
            <span class="ml-3" v-html="localize(value)"></span>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!--  <b-row>
      <b-col>
        <h2 class="h2 font-handwriting text-center">
          {{ localize("Thousands Of Authors Are Now Using QuietQuill To Write Better And Faster. Now It's Your Turn.") }}
        </h2>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="12" md="6" class="pr-0 pr-md-2">
        <img class="d-block mt-3" src="/img/reviews/1.png" />
        <img class="d-block mt-3" src="/img/reviews/2.png" />
        <img class="d-block mt-3" src="/img/reviews/3.png" />
      </b-col>
      <b-col cols="12" md="6" class="pl-0 pl-md-2">
        <img class="d-block mt-3" src="/img/reviews/4.png" />
        <img class="d-block mt-3" src="/img/reviews/5.png" />
        <img class="d-block mt-3" src="/img/reviews/6.png" />
      </b-col>
    </b-row> -->

    <b-row class="mt-5 pt-0 pt-md-5">
      <b-col cols="12" md="6" class="d-none d-md-block">
        <div>
          <img
            class="d-block w-100"
            src="@/assets/images/lp-banner.png"
            alt="Claim your Draft book now"
            style="filter: sepia(100%) grayscale(60%);"
          />
        </div>

        <div class="d-flex align-items-center justify-content-center flex-wrap mt-4">
          <div class="d-flex mr-3 mb-3">
            <img src="@/assets/images/forbes-thailand.png" width="100" class="mr-3 mb-3" />
          </div>
        </div>
      </b-col>

      <b-col cols="12" md="6">
        <StepsPromo @click="$emit('next')" :introduction="introduction" :steps="steps" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import StepsPromo from "@/components/Data/StepsPromo.vue";

export default {
  name: "Start",
  components: { BCol, BRow, StepsPromo },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    introduction:
      "What if you could train your own AI Teammate to write like you, create blog post up to 5.000 words and so much more (emails, sale pages etc.)?",
    steps: [
      `Insert the URL of your own blog.`,
      "Louis AI will analyze it, and learn to mimic your writing style.",
      "Describe your idea for a blog post, and write the first three for free.",
    ],
    pros: [
      `<b>Automated Keyword Research:</b> Dive into your topic with keywords generated by advanced AI, tailored to boost your content's visibility.`,
      `<b>Dynamic Heading Structure:</b> Effortlessly organize your articles with AI-crafted headings that capture attention and enhance readability.`,
      `<b>Instant Meta Magic:</b> Generate compelling meta descriptions and titles that elevate your content's click-through rate.`,
      //`<b>Optimized Link Integration:</b> Improve your site's authority with smartly placed, optimized links within your article.`,
      `<b>Seamless Text Formatting:</b> Achieve the perfect article layout with AI-guided text formatting, making your content more engaging.`,
      // `<b>Content Strategy Optimization:</b> Receive personalized recommendations to align your articles with the latest SEO trends and best practices.`,
      `<b>Time-Saving Article Copy Creation:</b> Quickly produce long-form SEO blog posts that are primed for top search engine rankings.`,
      `<b>Personalized AI Writing Style:</b> Train MeetLouis.ai with your unique writing samples, enabling it to craft articles that mirror your personal tone and style, ensuring your content remains authentic and true to your voice.`,
    ],
  }),
  methods: {
    toSlug,
    localize(value) {
      return value;
    },
  },
};
</script>

<style lang="scss">
.start-landing-teammate .steps-promo {
  background-color: #986bef !important;

  li,
  p {
    color: white !important;
  }
}
</style>
