<template>
  <div data-cy="wizard-context">
    <b-row no-gutters>
      <b-col>
        <h1 class="text-uppercase text-center">
          Experience The Future Of SEO Now
        </h1>

        <h2 class="text-center">
          Leverage Custom AI for Content That Ranks, Captures and Converts
        </h2>
      </b-col>
    </b-row>

    <div v-if="isLoading" class="mt-50 ml-auto mr-auto pl-4 pr-4 pb-4 bg-dark-jungle rounded-lg" style="max-width: 655px">
      <vue-lottie-player
        name="scooterAnim"
        loop
        :animation-data="require('@/assets/animations/spinner.json')"
        class="ml-auto mr-auto"
        style="height: 100px"
      />

      <div class="mt-3 pt-3 border-top">
        <h5 class="text-uppercase">Checklist</h5>
        <base-checklist :items="tasksList" />
      </div>

      <div class="mt-3 pt-3 border-top">
        <img src="@/assets/character/smiling.png" class="float-left" width="100" />
        <p class="p-3 bigger">
          I'm currently checking your site... Give me just 30 seconds to understand who you are and give you my suggestions.<br />
        </p>
      </div>
    </div>

    <div v-else>
      <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width: 825px">
        <img src="@/assets/character/smiling.png" class="float-left" width="100" />
        <p class="bigger">
          All right!<br />
          I've got a better understanding of you. Let's be efficient and write an SEO blog post right now.<br />
        </p>

        <p class="bigger">
          I've written a few suggestions with the name and a description of the post to write.<br />
          You can either select one of my suggestions, or describe the blog post you'd like.
        </p>

        <h5 class="text-uppercase">Suggestions</h5>

        <div
          v-for="(suggestion, index) in suggestionsList"
          :key="index"
          class="mt-3 p-3 bg-transparent-success rounded-top-right-lg rounded-bottom-right-lg"
        >
          <h5 class="mb-0 text-capitalize">
            {{ suggestion.title }}
            <b-badge variant="success">{{ suggestion.structureId }}</b-badge>
          </h5>

          <p v-html="suggestion.description" class="mb-0" />
          <p>
            Optimized for the keywords <em>{{ suggestion.keywords }}</em>
          </p>

          <div class="text-right">
            <b-button @click="nextStep(suggestion)" variant="success" :disabled="isLoading" pill>
              Continue
            </b-button>
          </div>
        </div>
      </div>

      <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width: 825px">
        <h5 class="text-uppercase">Or Describe The Blog Post You Need To Write...</h5>
        <form-builder
          :state-machine="stateMachine"
          :components-list="componentsList"
          :custom-validators="{}"
          :next-button="{ text: 'Continue', variant: 'success' }"
          :is-sending="isSending"
          @submit="nextStep"
          class="form-wizard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TRIGGER_EVENT } from "@/store/actions";

import { BRow, BCol, BBadge, BButton } from "bootstrap-vue";
import cloneDeep from "lodash/cloneDeep";
import VueLottiePlayer from "vue-lottie-player";
import { FormBuilder } from "ui-stepper";

import { visitorFetchWordpressPosts, visitorSuggestPosts } from "@/common/functions";

import { LANDING_STATE_MACHINE_SEO_POST } from "@/constants/project";

import BaseChecklist from "@/components/Data/Checklist";

export default {
  name: "LandingSuggestions",
  components: { BRow, BCol, BBadge, BButton, VueLottiePlayer, FormBuilder, BaseChecklist },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    stateMachine: null,
    componentsList: {
      Message: () => ({
        component: import("@/components/Forms/TipTap/Paragraph.vue"),
        timeout: 3000,
      }),
      SeoKeywords: () => ({
        component: import("@/components/Forms/Base/SeoKeywords.vue"),
        timeout: 3000,
      }),
    },

    suggestionsList: [],
    tasksList: [
      { status: false, text: "Checking URL" },
      { status: false, text: "Thinking of the best SEO strategy" },
    ],

    value: "",

    isLoading: false,
    isSending: false,
  }),
  created() {
    this.stateMachine = cloneDeep(LANDING_STATE_MACHINE_SEO_POST);
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    async init() {
      this.isLoading = true;

      let { settings } = this;

      const {
        data: { name, nameShorten = "", description, posts },
      } = await visitorFetchWordpressPosts(settings);

      this.tasksList[0].status = true;

      console.log("name", name);
      console.log("description", description);
      console.log("posts", posts);
      settings = { ...settings, name, nameShorten, description, posts };
      this.$emit("update:settings", settings);

      const postsSanitized = posts.map((post) => `Title: ${post.title}, Content: ${post.content}, Link: ${post.link}`).join("\n");
      const {
        data: { items },
      } = await visitorSuggestPosts({ ...settings, postsSanitized });

      this.suggestionsList = items;
      this.tasksList[0].status = true;

      this.isLoading = false;
    },
    async nextStep(value) {
      this.isLoading = true;

      if (!value) {
        const { formData } = this.stateMachine.context;
        value = cloneDeep(formData);
      }

      this.$emit("update:blogPost", value);
      this.$emit("next-step");

      this.isLoading = false;
    },

    handleCurrentStep(value) {
      this.triggerEvent({ event: "landing_step", step: value, vueInstance: this });
      this.currentStepIndex = value;
    },
    handleUseSuggestion() {},
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings";

.landing-context {
  small {
    font-size: 0.7em;
    display: block;
    color: $gray-darker;
  }
}

legend {
  width: auto;
  font-size: 0.8em;
  color: gray;
  font-weight: 600;
  margin: 0;
}

fieldset {
  border: 2px solid rgb(246 246 246);
  padding: 10px 25px 25px 25px;
  margin-bottom: 1em;
}
</style>
