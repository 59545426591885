<template>
  <div>
    <b-row no-gutters>
      <b-col>
        <h1 class="text-uppercase text-center">
          Get Louis AI To Write Your Next Blog Post In Minutes
        </h1>

        <h2 class="text-center">I'm Ready To Write {{ item.title }}</h2>
      </b-col>
    </b-row>

    <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width: 825px">
      <p>
        Thank you!<br />
        I've got almost everything to write your first blog post. Before writing, <strong>create your account now</strong>.
      </p>

      <register-with-email
        v-on="$listeners"
        title=""
        cta="Write Now"
        footprint="By clicking the 'Write Now' button, you agree to the Terms and Conditions and Privacy Statement."
        :is-landing="true"
        :is-showing-confirmation="false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TRIGGER_EVENT } from "@/store/actions";

import { BRow, BCol } from "bootstrap-vue";

import RegisterWithEmail from "@/components/Forms/RegisterWithEmail";

export default {
  name: "WizardRegister",
  components: { BRow, BCol, RegisterWithEmail },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.triggerEvent({
      event: "landing_step",
      step: "last",
      vueInstance: this,
    });
  },
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
  },
};
</script>
