<template>
  <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto steps-promo" style="max-width: 825px">
    <p class="ml-4 mr-4 mb-4">
      <strong>
        {{ introduction }}
      </strong>
    </p>

    <ul class="steps text-left reset-list">
      <li v-for="(s, index) in steps" :key="index" class="text-left mb-3">
        <b class="step-title">Step {{ index + 1 }}</b>
        <div v-html="s" />
      </li>
    </ul>

    <b-button @click="$emit('click')" class="mt-4 mb-3 pl-4 pr-4 w-100 text-l" variant="success" pill>
      {{ callToAction }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

export default {
  name: "StepsPromo",
  components: { BButton },
  props: {
    introduction: {
      type: String,
      default: "What if you had the power to transform a single sentence into a complete SEO post up to 5.000 words?",
    },
    steps: {
      type: Array,
      default: () => {
        return [
          `<p class='mb-0'>Explain your idea, and topics you want to cover.</p>
<p><em>OPTIONAL: Import your existing blog posts inside MeetLouis for it to mimic your writing style and keep it consistent.</em>`,
          "Louis AI will write your blog post, format it and create the images.",
          "Write your first three blog posts (for free).",
        ];
      },
    },
    callToAction: {
      type: String,
      default: "Start Now (For FREE)",
    },
  },
  methods: {
    toSlug,
    localize(text, slug = "") {
      return this.$options.filters.__t(text, "lp__text__" + (slug || toSlug(text)));
    },
  },
};
</script>
