<template>
  <div class="checklist">
    <div class="checklist-item" v-for="(item, index) in items" :key="item.id">
      <div class="checklist-icon">
        <img v-if="item.status === 'success'" src="@/assets/icons/check.svg" width="30" height="30" />
        <img v-else src="@/assets/icons/empty.svg" width="30" height="20" />
      </div>

      <div class="checklist-content">
        <p class="task-index mb-0">
          <del v-if="item.status === 'success'" class="text-success font-italic">
            {{ "Task" | __t("projects__task__headline__task") }} {{ index + 1 }}
          </del>
          <span v-else>{{ "Task" | __t("projects__task__headline__task") }} {{ index + 1 }}</span>
        </p>

        <p class="task-text mb-0">
          <small class="text-graylight">
            <del v-if="item.status === 'success'" class="text-success">
              <em>{{ item.text | __t(`projects__task__checklist__${toSlug(item.text)}`) }}</em>
            </del>
            <span v-else>{{ item.text | __t(`projects__task__checklist__${toSlug(item.text)}`) }}</span>
          </small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { toSlug } from "@/helpers/utils";

export default {
  name: "Checklist",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toSlug,
  },
};
</script>

<style scoped>
.checklist {
  display: flex;
  flex-direction: column;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Adjust spacing between items */
}

.checklist-icon {
  margin-right: 1rem; /* Adjust spacing between icon and text */
}

.checklist-content {
  flex-grow: 1;
}
</style>
