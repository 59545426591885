<template>
  <div data-cy="login">
    <errors v-if="errorMessage" :error="errorMessage" class="mb-4 mt-1" data-cy="login-errors" />

    <b-form v-if="!isSuccess" @submit="triggerRegister">
      <h2 v-if="title" class="mt-2 text-l">
        {{ title | __t(`forms__login-by-link__${toSlug(title)}`) }}
      </h2>

      <fieldset class="form-group mt-3" data-cy="login-fieldset">
        <label for="email" class="mb-1">
          <strong>{{ "Email:" | __t("forms__login-by-link__email") }}</strong>
        </label>

        <base-input
          name="email"
          type="email"
          id="email"
          v-model="email"
          :disabled="isSending"
          placeholder="mycoolmail@email.com"
          theme-border="border"
          @keyup.enter="triggerRegister"
          :theme="theme"
        />

        <input-error
          v-if="!$v.email.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="login-error"
        />

        <input-error v-if="!$v.email.email" :error-code="'email'" :has-submit-error.sync="hasSubmitError" data-cy="login-error" />
      </fieldset>

      <div class="text-center mt-3">
        <b-button
          @click="triggerRegister"
          class="mt-4 mb-3 pl-4 pr-4 w-100"
          variant="success"
          data-cy="login-submit"
          :disabled="isSending"
          pill
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-magic"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"
            />
          </svg>

          {{ cta | __t(`forms__login-by-link__${toSlug(cta)}`) }}
        </b-button>

        <small style="color: gray">
          {{ footprint | __t(`forms__login-by-link__${toSlug(footprint)}`) }}
        </small>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { REGISTER } from "@/store/actions";

import { BForm, BButton } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { toSlug, getLanguageFromBrowser } from "@/helpers/utils";

import Errors from "@/components/Data/Errors";
import InputError from "@/components/Data/ErrorsInput";
import BaseInput from "@/components/Forms/Base/BaseInput";

export default {
  name: "RegisterWithEmailForm",
  mixins: [validationMixin],
  components: { Errors, InputError, BForm, BaseInput, BButton },
  props: {
    title: {
      type: String,
      default: "Login With Magic Link",
    },
    cta: {
      type: String,
      default: "Request Magic Link",
    },
    footprint: {
      type: String,
      default: "A one-click login/register link will be emailed to you",
    },
    bookName: {
      type: String,
      default: "",
    },

    isShowingConfirmation: {
      type: Boolean,
      defaut: true,
    },
    isLanding: {
      type: Boolean,
      defaut: false,
    },
    theme: {
      type: String,
      default: "white",
      validator: (v) => ["white", "beige", "dark"].includes(v),
    },
  },
  data() {
    return {
      email: null,

      errorMessage: null,

      hasSubmitError: null,
      isSending: false,
      isSuccess: false,
    };
  },
  methods: {
    ...mapActions("auth", { register: REGISTER }),
    async triggerRegister(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { language: forcedLanguage } = this.$route.query;
      const { email } = this;
      const language = forcedLanguage || getLanguageFromBrowser();

      const { isSuccess, error } = await this.register({ email, language });

      if (isSuccess) {
        this.$emit("success");
        this.isSending = false;
        return;
      }

      this.errorMessage = error;
      this.isSending = false;
    },

    toSlug,
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
